<template>
  <div class="login">
    <div class="lang-switcher">
      <el-dropdown trigger="click" @command="selectLang">
        <span class="el-dropdown-link">
          <span v-if="langcode === 'en'">
            Language<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <span v-if="langcode === 'zh'">
            語言<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="en">English</el-dropdown-item>
          <el-dropdown-item command="zh">繁體中文</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-container class="login-wrapper">
      <el-main>
        <h1>HON FUNG HONG KONG</h1>
        <h2 class="page-title">{{ $t('Login to System Admin Panel') }}</h2>
        <div class="login-form">
          <el-form ref="form" :model="form">
            <el-form-item :label="$t('Username')">
              <el-input v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Password')">
              <el-input type="password" v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item>
              <button class="btn btn-primary login-button" @click.prevent="login">{{ $t('Login') }}</button>
            </el-form-item>
          </el-form>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Cookies from 'js-cookie';

export default {
  name: 'Login',
  data(){
    return{
      form:{
        username: '',
        password: '',
      },
    }
  },
  mounted(){
    const loginToken = Cookies.get('loginToken');
    if(loginToken){
      this.$router.push('/dashboard');
      this.startLogin();
    }
  },
  methods: {
    selectLang(langcode){
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
    },
    async login(){
      const apiUrl = `${this.apiUrl}/login/admin`;
      const username = this.form.username
      const password = this.form.password;

      let loginData = {
        username,
        password,
      };

      const loginResult = await fetch(apiUrl, {
        method: 'POST',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(loginData),
      });
      const status = await loginResult.status;

      if(status !== 200){
        this.$message.error(`Cannot be login. Please confirm your username and password is correct.`);
        return;
      }
      const resultJson = await loginResult.json();
      const token = resultJson['token'];
      const userId = resultJson['user_id'];
      Cookies.set('loginToken', token);
      Cookies.set('userId', userId);

      this.startLogin();
      this.$router.push('/dashboard');
    },
    ...mapActions(['startLogin', 'changeLang']),
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.logo{
  max-width: 10%;
  margin-top: 100px;
}
.login{
  display: flex;
  align-items: center;
  position: relative;
}
.lang-switcher{
  position: absolute;
  top: 20px;
  right: 20px;
  color: black;
  .el-dropdown-link{
    color: black;
    &:hover{
      cursor: pointer;
    }
  }
}
.login-wrapper{
  position: relative;
  bottom: 90px;

  .page-title{
    color: black;
    margin-top: 20px;
  }

  .login-form{
    max-width: 510px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;

    .el-form-item{
      .el-form-item__label{
        color: black !important;
      }
    }

    .login-button{
      width: 100%;
      text-transform: uppercase;
      font-size: 1.3em;
      margin-top: 20px;
    }
  }
}
</style>
